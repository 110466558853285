.background {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-image: url('../Img/3.png');
}

.homepage {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  border-left: 1px solid #d9d9d9;
    border-radius: 2px;
  background-image: url('../Img/4.png');
}

.transparent {
    background: rgba(0,0,0,0);
    height: 10vh;
}

.whites {
  background: rgb(250, 248, 248);
  height: 10vh;
}

.logo { 
    height:48px;
    width: 90%;
    margin:8px;       
}

#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 8px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.site-card-border-less-wrapper {
  padding: 3vh;
  background: #ececec;
}

.card_height{
  height: 84vh;
}

.footer_height {
  height: 10vh;
}

.cardposition {
  position: left;
 
}

.card {
    Height: "100%";
  }
.cardBody {
     height: 62vh;
    overflow: "auto";
  }

[data-theme='compact'] .ant-advanced-search-form,
.ant-advanced-search-form {
  padding: 12px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='compact'] .ant-advanced-search-form .ant-form-item,
.ant-advanced-search-form .ant-form-item {
  display: flex;
}

[data-theme='compact'] .ant-advanced-search-form .ant-form-item-control-wrapper,
.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.ant-form {
  max-width: none;
}

.search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}

[data-theme="dark"] .ant-advanced-search-form {
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid #434343;
  padding: 12px;
  border-radius: 2px;
}

[data-theme="dark"] .search-result-list {
  border: 1px dashed #434343;
  background: rgba(255, 255, 255, 0.04);
}

.site-page-header-ghost-wrapper {
  /*padding: 4px;*/
  background-color: #f5f5f5;
  width: '100%';
}

.site-page-header-ghost-wrapper2 {
  padding: 10px;
  background-color: #f5f5f5;
}